export const listTypeDocument = [
  { textTypeDocument: 'NIT', valueTypeDocument: '31' },
  { textTypeDocument: 'CC', valueTypeDocument: '13' },
]
export const listTypePerson = [
  { textTypePerson: 'Persona Juridica', valueTypePerson: '1' },
  { textTypePerson: 'Persona Natural', valueTypePerson: '2' },
]
export const listTributo = [
  { textTributo: '[01] IVA', valueTributo: '01' },
  { textTributo: '[04] INC', valueTributo: '04' },
  { textTributo: '[ZA] IVA e INC', valueTributo: 'ZA' },
  { textTributo: '[ZZ] Otros Tributos', valueTributo: 'ZZ' },
]

export const listObligaciones = [
  { textObligaciones: '[O-13] Gran Contribuyente', valueObligaciones: 'O-13' },
  { textObligaciones: '[O-15] Autorretenedor', valueObligaciones: 'O-15' },
  { textObligaciones: '[O-23] Agente de retención IVA', valueObligaciones: 'O-23' },
  { textObligaciones: '[O-47] Régimen simple de tributación', valueObligaciones: 'O-47' },
  { textObligaciones: '[R-99-PN] No aplica - Otros*', valueObligaciones: 'R-99-PN' },
]
