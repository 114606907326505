<template>
  <div>
    <v-form ref="form">
      <base-card>

    <v-card-text>
      <v-row>
        <v-col cols='12' sm='4'>
          <v-text-field
            ref='Nit'
            v-model='datosEmisor.Nit'
            :error-messages='errorMessages'
            clearable
            type="number"
            label='Nit'
            :rules="nitFormRules"
            prepend-inner-icon='mdi-card'
            required
          />
        </v-col>

        <v-col cols='12' sm='4'>
          <v-select
            ref='TipoIdentificacion'
            v-model='datosEmisor.TipoIdentificacion'
            :error-messages='errorMessages'
            :items='listTypeDocument'
            :rules='formRules'
            item-text='textTypeDocument'
            item-value='valueTypeDocument'
            label='Tipo Identificación'
            prepend-inner-icon='mdi-account-network'
            required
          />
        </v-col>

        <v-col cols='12' sm='4'>
          <v-select
            ref='TipoPersona'
            v-model='datosEmisor.TipoPersona'
            :error-messages='errorMessages'
            :items='listTypePerson'
            :rules='formRules'
            item-text='textTypePerson'
            item-value='valueTypePerson'
            label='Tipo Persona'
            prepend-inner-icon='mdi-file-chart'
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' sm='6'>
          <v-text-field
            ref='NombreComercial'
            v-model='datosEmisor.NombreComercial'
            :error-messages='errorMessages'
            :rules='formRules'
            clearable
            label='Nombre Comercial'
            prepend-inner-icon='mdi-account-tie'
            required
          />
        </v-col>
        <v-col cols='12' sm='6'>
          <v-text-field
            ref='RazonSocial'
            v-model='datosEmisor.RazonSocial'
            v-validate-role='permisosRoles'
            :error-messages='errorMessages'
            :rules='formRules'
            clearable
            label='Razon Social'
            prepend-inner-icon='mdi-account-tie'
            required
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols='12' sm='6'>
          <v-select
            ref='Tributo'
            v-model='datosEmisor.Tributo'
            :error-messages='errorMessages'
            :items='listTributo'
            :rules='formRules'
            item-text='textTributo'
            item-value='valueTributo'
            label='Tributo'
            prepend-inner-icon='mdi-file-chart'
            required
          />
        </v-col>
        <v-col cols='12' sm='6'>
          <v-select
            ref='Obligaciones'
            v-model='datosEmisor.Obligaciones'
            :error-messages='errorMessages'
            :items='listObligaciones'
            :rules='formRules'
            item-text='textObligaciones'
            item-value='valueObligaciones'
            label='Obligaciones'
            prepend-inner-icon='mdi-file-chart'
            required
            multiple
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' sm='4'>
          <v-text-field
            ref='NombreContacto'
            v-model='datosEmisor.NombreContacto'
            :error-messages='errorMessages'
            :rules='formRules'
            clearable
            label='Nombre Contacto'
            prepend-inner-icon='mdi-account-tie'
            required
          />
        </v-col>
        <v-col cols='12' sm='4'>
          <v-text-field
            ref='EmailContacto'
            v-model='datosEmisor.EmailContacto'
            v-validate-role='permisosRoles'
            :error-messages='errorMessages'
            type="email"
            :rules="[v => /.+@.+\..+/.test(v) || 'Correo no válido']"
            clearable
            label='Email Contacto'
            prepend-inner-icon='mdi-email'
            required
          />
        </v-col>
        <v-col cols='12' sm='4'>
          <v-text-field
            ref='TelefonoContacto'
            v-model='datosEmisor.TelefonoContacto'
            v-validate-role='permisosRoles'
            :error-messages='errorMessages'
            :rules='formRules'
            clearable
            type="number"
            label='Teléfono Contacto'
            prepend-inner-icon='mdi-phone'
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            ref="Departamento"
            v-model="datosEmisor.Departamento"
            :items="detailDepartaments"
            item-text="nombre"
            item-value="idProvincia"
            label="Departamento"
            prepend-inner-icon="mdi-map"
            :rules="formRules"
            :error-messages="errorMessages"
            filter
            search-input
            required
            @change="getCitys"
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-text-field
                      class="search-field"
                      v-model="search"
                      placeholder="Buscar"
                      prepend-inner-icon="mdi-magnify"
                      hide-details
                    ></v-text-field>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            ref="Ciudad"
            v-model="datosEmisor.Ciudad"
            :items="detailCitys"
            item-text="nombre"
            item-value="idPoblacion"
            label="Ciudad"
            prepend-inner-icon="mdi-city"
            :rules="formRules"
            :error-messages="errorMessages"
            filter
            search-input
            required
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-text-field
                      class="search-field"
                      v-model="search"
                      placeholder="Buscar"
                      prepend-inner-icon="mdi-magnify"
                      hide-details
                    ></v-text-field>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col cols='12' sm='4'>
          <v-text-field
            ref='Direccion'
            v-model='datosEmisor.Direccion'
            v-validate-role='permisosRoles'
            :error-messages='errorMessages'
            :rules='formRules'
            clearable
            label='Direccion'
            prepend-inner-icon='mdi-home'
            required
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-btn
            class="ma-2"
            style="
                    background-color: rgb(204 33 40);
                    color: white !important;
                  "
            @click="validateEmisor"
          >
            Actualizar
          </v-btn>
        </v-col>
        <v-alert v-show="successAlert" :type="alertType" text>
          {{ msgAlert }}
        </v-alert>
      </v-row>

    </v-card-text>
      </base-card>
    </v-form>

  </div>
</template>

<script>
import {listObligaciones, listTributo, listTypeDocument, listTypePerson} from "@/data/listConfigEmisor";

export default {
  name: 'campoEditEmisor',
  props: ['datosEmisor'],

  data() {
    return {
      listTypeDocument: listTypeDocument,
      listTributo: listTributo,
      listTypePerson: listTypePerson,
      listObligaciones: listObligaciones,
      permisosRoles: ["administrador"],
      errorMessages: "",
      search: '',
      formHasErrors: null,
      successAlert: false,
      alertType: "success",
      msgAlert: "Datos Emisor Actualizados Correctamente.",
      formRules: [(v) => !!v || "Este campo es requerido"],
      nitFormRules: [
        (v) => !!v || "Este campo es requerido y SIN dígito de verificación",
        (v) => v > 0 || "Este campo no acepta números negativos",
      ],

    }
  },
  computed: {

    form() {
      return {
        Compania: this.datosEmisor.Compania,
        Nit: this.datosEmisor.Nit,
        TipoIdentificacion: this.datosEmisor.TipoIdentificacion,
        TipoPersona: this.datosEmisor.TipoPersona,
        NombreComercial: this.datosEmisor.NombreComercial,
        RazonSocial: this.datosEmisor.RazonSocial,
        NombreContacto: this.datosEmisor.NombreContacto,
        EmailContacto: this.datosEmisor.EmailContacto,
        TelfonoContacto: this.datosEmisor.TelfonoContacto,
        Tributo: this.datosEmisor.Tributo,
        Obligaciones: this.datosEmisor.Obligaciones,
        Pais: this.datosEmisor.Pais,
        Departamento: this.datosEmisor.Departamento,
        Ciudad: this.datosEmisor.Ciudad,
      };
    },
    detailDepartaments() {
      return (this.$store.state.emisor.listDepartaments.Mensaje || [])
        .filter((departament) =>
          departament.nombre.toLowerCase().includes(
            this.search.toLowerCase()
          )
        )
        .map((departament) => {
          return {
            ...departament,
            nombre: `${departament.nombre.toUpperCase()} - ${departament.idProvincia}`,
          };
        })
        .sort((a, b) => {
          if (a.nombre < b.nombre) return -1;
          if (a.nombre > b.nombre) return 1;
          return 0;
        });
    },
    detailCitys() {
      return (this.$store.state.emisor.listCitys.Mensaje || [])
        .filter((city) =>
          city.nombre.toLowerCase().includes(
            this.search.toLowerCase()
          )
        )
        .map((city) => {
          return {
            ...city,
            nombre: `${city.nombre.toUpperCase()} - ${city.idPoblacion}`,
          };
        })
        .sort((a, b) => {
          if (a.nombre < b.nombre) return -1;
          if (a.nombre > b.nombre) return 1;
          return 0;
        });
    },
    updateStatusResponse () {
      return this.$store.state.emisor.updateStatusResponse
    },
  },
  watch:
    {
      updateStatusResponse  () {
        if (this.updateStatusResponse === true) {
          this.generateAlertSuccess()
          this.$store.dispatch('emisor/setUpdateResponseEmisor', false)
        }
      },
    },
  created() {
    this.getCitys()
  },
  methods: {
    generateAlertSuccess () {
      this.successAlert = true
      setTimeout(() => {
        this.successAlert = false
      }, 3000)
    },
    async updateDataEmisor() {
      await this.$store.dispatch('emisor/updateEmisor', this.datosEmisor)
    },
    async getCitys(selectedDepartament) {
      if (selectedDepartament == null) {
        await this.$store.dispatch('emisor/getListCitys', {departamento: this.datosEmisor.Departamento});
      } else {
        await this.$store.dispatch('emisor/getListCitys', {departamento: selectedDepartament});

      }
    },
    validateEmisor() {

      this.formHasErrors = !this.$refs.form.validate();

      if (this.formHasErrors === false) this.updateEmisor();

    },
    updateEmisor() {

      this.selectedValueTributo = this.datosEmisor.Tributo;
      const selectedTributo = this.listTributo.find(
        (item) => item.valueTributo === this.selectedValueTributo
      );

      if (selectedTributo) {
        // Extraer lo que está después de [XX]
        const match = selectedTributo.textTributo.match(/\[.*?\]\s*(.*)/);
        this.datosEmisor.NombreTributo = match ? match[1] : ''; // Asigna solo el texto después de los corchetes
      } else {
        this.datosEmisor.NombreTributo = '';
      }


      this.selectedValuePersona = this.datosEmisor.TipoPersona;
      const selectedPersona = this.listTypePerson.find(
        (item) => item.valueTypePerson === this.selectedValuePersona
      );
      this.datosEmisor.NombreTipoPersona = (selectedPersona ? selectedPersona.textTypePerson : '');

      this.selectedValueIdentificacion = this.datosEmisor.TipoIdentificacion;
      const selectedIdentificacion = this.listTypeDocument.find(
        (item) => item. valueTypeDocument === this.selectedValueIdentificacion
      );
      this.datosEmisor.NombreTipoIdentificacion = (selectedIdentificacion ? selectedIdentificacion.textTypeDocument : '');
      this.datosEmisor.Pais ="COLOMBIA";

      this.$store.dispatch("emisor/updateEmisor", {
        ...this.datosEmisor,
      });
    }
  }
}
</script>

<style scoped>

</style>
